import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reducer, { initialState } from './store/reducer';
import thunk from 'redux-thunk';

import './index.css';
import * as serviceWorker from './serviceWorker';

import MainApp from './App.js';

class AuthWrapper extends React.Component {
  rerender = () => this.forceUpdate()
  render() {
    return <MainApp rerender={this.rerender} />
  }
}

// dev tools middleware
const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

const enhancers = compose(
  composeSetup(applyMiddleware(thunk))
);

export const store = createStore(
  reducer, initialState, enhancers
);
ReactDOM.render(<Provider store={store}><AuthWrapper /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
