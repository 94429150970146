export const INIT_USER_SET = 'INIT_USER_SET';
export const SET_USER_ID = 'SET_USER_ID';
export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const INIT_USER_DATA_GET = 'INIT_USER_DATA_GET';

export const UI_LOADING = 'UI_LOADING';
export const UI_LOADING_COMPLETE = 'UI_LOADING_COMPLETE';

export const SET_HOME_ID = 'SET_HOME_ID';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const USER_VIEWMODE_UPDATE = 'USER_VIEWMODE_UPDATE';
export const ON_DISMISS_ALERT_MESSAGE = 'ON_DISMISS_ALERT_MESSAGE';
export const ON_SHOW_ALERT_MESSAGE = 'ON_SHOW_ALERT_MESSAGE';