import { CHECKIN_TYPE } from "../constants";
import { createCheckin } from "../store/actions/checkinActions";

export const jobsiteNewContractorMessage = (home, user) => {
    createCheckin(CHECKIN_TYPE.CONTRACTOR_JOBSITE_NEW,
        home,
        user);
}

export const placeNewGuestMessage = (home, user) => {
    createCheckin(CHECKIN_TYPE.GUEST_PLACE_NEW,
        home,
        user);
}

export const placeUnsubscribeGuestMessage = (home, user) => {
    createCheckin(CHECKIN_TYPE.GUEST_PLACE_UNSUBSCRIBE,
        home,
        user);
}

export const jobsiteUnsubscribeContractorMessage = (home, user) => {
    createCheckin(CHECKIN_TYPE.CONTRACTOR_JOBSITE_UNSUBSCRIBE,
        home,
        user);
}