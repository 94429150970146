export const NO_HOME_ID = 'empty';
export const NO_PHONE_NUMBER = 'empty';
export const NO_EMAIL_ADDRESS = 'empty';
export const NO_HOME_ADDRESS = '';
export const EMPTY_PROFILE = {};
export const NO_ACTIVE_CHECKIN = 'NONE';
export const NO_ACTIVE_HOME = 'NONE';
export const LEFT_HOME_DEFAULT = '0';
export const EMPTY_MESSAGE = '_EMPTY';
export const GREEN_COLOR = 'rgb(77, 189, 116)';
export const BLUE_COLOR = 'rgb(32, 168, 216)';
export const RED_COLOR = 'rgb(248, 108, 107)';
export const INACTIVE_COLOR = 'rgb(230, 230, 230)';

export const CHECKIN_BUTTON_WORD = 'Check-in';

export const CHECKIN_TYPE = {

    LANDLORD_HOME_NEW: "LANDLORD_HOME_NEW",
    GUEST_PLACE_NEW: "GUEST_PLACE_NEW",
    CONTRACTOR_JOBSITE_NEW: "CONTRACTOR_JOBSITE_NEW",

    GUEST_CHECKIN: "GUEST_CHECKIN",
    CONTRACTOR_CHECKIN: "CONTRACTOR_CHECKIN",

    GUEST_CHECKOUT: "GUEST_CHECKOUT",
    CONTRACTOR_CHECKOUT: "CONTRACTOR_CHECKOUT",

    LANDLORD_HOME_UNSUBSCRIBE: "LANDLORD_HOME_UNSUBSCRIBE",
    GUEST_PLACE_UNSUBSCRIBE: "GUEST_PLACE_UNSUBSCRIBE",
    CONTRACTOR_JOBSITE_UNSUBSCRIBE: "CONTRACTOR_JOBSITE_UNSUBSCRIBE",

    GUEST_PLACE_BLOCK: "GUEST_PLACE_BLOCK",
    CONTRACTOR_JOBSITE_BLOCK: "CONTRACTOR_JOBSITE_BLOCK",
    LANDLORD_HOME_BLOCK: "LANDLORD_HOME_BLOCK",

    GENERAL_NOTIFICATION: "GENERAL_NOTIFICATION",
    GUEST_NOTIFICATION: "GUEST_NOTIFICATION",
    LANDLORD_NOTIFICATION: "LANDLORD_NOTIFICATION",
    CONTRACTOR_NOTIFICATION: "CONTRACTOR_NOTIFICATION",

};

export const checkinTypeShowsMessageBox = (checkinType) => {
    return [
        CHECKIN_TYPE.GENERAL_NOTIFICATION,
        CHECKIN_TYPE.GUEST_NOTIFICATION,
        CHECKIN_TYPE.LANDLORD_NOTIFICATION,
        CHECKIN_TYPE.CONTRACTOR_NOTIFICATION,
        CHECKIN_TYPE.GUEST_CHECKIN,
        CHECKIN_TYPE.CONTRACTOR_CHECKIN,
        CHECKIN_TYPE.GUEST_CHECKOUT,
        CHECKIN_TYPE.CONTRACTOR_CHECKOUT].indexOf(checkinType) !== false
}

export const checkinTypeImmediatelySetsWhenOut = (checkinType) => {
    return [
        CHECKIN_TYPE.GENERAL_NOTIFICATION,
        CHECKIN_TYPE.GUEST_NOTIFICATION,
        CHECKIN_TYPE.LANDLORD_NOTIFICATION,
        CHECKIN_TYPE.CONTRACTOR_NOTIFICATION

    ].indexOf(checkinType) !== false
}

export const checkinTypeShowsTimeLabels = (checkinType) => {
    return [
        CHECKIN_TYPE.GUEST_CHECKIN,
        CHECKIN_TYPE.CONTRACTOR_CHECKIN,
        CHECKIN_TYPE.GUEST_CHECKOUT,
        CHECKIN_TYPE.CONTRACTOR_CHECKOUT].indexOf(checkinType) !== false
}

export const checkinTypeShowsRatings = (checkinType) => {
    return [
        CHECKIN_TYPE.GUEST_CHECKOUT,
        CHECKIN_TYPE.CONTRACTOR_CHECKOUT].indexOf(checkinType) !== false
}

export const checkinMessageFriendly = (type) => {
    switch (type) {
        case CHECKIN_TYPE.LANDLORD_HOME_NEW: return "became a landlord";
        case CHECKIN_TYPE.GUEST_PLACE_NEW: return "pre-checked a guest";
        case CHECKIN_TYPE.GUEST_CHECKIN: return "checked in";
        case CHECKIN_TYPE.CONTRACTOR_JOBSITE_NEW: return "became a contractor";
        case CHECKIN_TYPE.LANDLORD_HOME_UNSUBSCRIBE: return "unsubscribed as landlord";
        case CHECKIN_TYPE.GUEST_PLACE_UNSUBSCRIBE: return "unsubscribed as guest";
        case CHECKIN_TYPE.CONTRACTOR_JOBSITE_UNSUBSCRIBE: return "unsubscribed as contractor";
        case CHECKIN_TYPE.GENERAL_NOTIFICATION: return "wrote this notification";
        case CHECKIN_TYPE.GUEST_NOTIFICATION: return "wrote this notification";
        case CHECKIN_TYPE.LANDLORD_NOTIFICATION: return "wrote this notification";
        case CHECKIN_TYPE.CONTRACTOR_NOTIFICATION: return "wrote this notification";
        default: return type;
    }
}