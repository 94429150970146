import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import aws_exports from './aws-exports';
import Loadable from 'react-loadable';
import { fetchUserAppData } from './store/actions/userActions';
import { INIT_USER_SET } from './store/actions/actions';
import DefaultLayoutMaxidomo from './containers/DefaultLayoutMaxidomo/DefaultLayoutMaxidomo';
import './App.scss';
import './aws-config.js';

// default styles
import '@aws-amplify/ui/dist/style.css';
Amplify.configure(aws_exports);

// Feb 2021
console.log("Maxidomo Build 20.02.04.10.3");

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

class App extends Component {

  signOut = () => {
    Auth.signOut()
      .then(
        data => {
          this.props.rerender();
        }).catch(
          err => console.log('Sign out error', err)
        );
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(user => {
      this.props.onReceivedUsernameFromCognito(user);
      fetchUserAppData(this.props.user);
    }).catch(err => console.log(err));
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route
            path="/"
            name="Home"
            render={routeProps => {
              return (
                <DefaultLayoutMaxidomo
                  signOut={this.signOut}
                  {...routeProps} />
              )
            }}
          />
        </Switch>
      </Router>
    );
  }
}

export default props => {
  const mapStateToProps = (state) => {
    return {
      user: state.user
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      onReceivedUsernameFromCognito: (userNameAndId) => {
        dispatch({ type: INIT_USER_SET, value: userNameAndId })
      }
    }
  }

  const AppComponent = connect(mapStateToProps, mapDispatchToProps)(withAuthenticator(App));
  return <AppComponent {...props} />
}