import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import { AppFooter, AppHeader } from '@coreui/react';

// sidebar nav config
import routes from '../../routes';
import CheckinModal from '../../components/Checkins/CheckinModal/CheckinModal';
import AlertMessage from '../../components/ui/AlertMessage';

const DefaultFooterMaxidomo = React.lazy(() => import('./DefaultFooterMaxidomo'));
const DefaultHeaderMaxidomo = React.lazy(() => import('./DefaultHeaderMaxidomo'));

class DefaultLayoutMaxidomo extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">    Loading maxidomo...</div>

  render() {
    return (this.props.user !== undefined ? (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeaderMaxidomo
              onLogout={e => this.signOut(e)}
              onCheckinClick={e => this.openCheckin()}
              signOut={e => this.props.signOut(e)} {...this.props} />
          </Suspense>
        </AppHeader>
        <div className="app-body" style={{ marginTop: '80px' }}>
          <main className="main">
            <Container fluid>
              <>
                <AlertMessage />
                <Suspense fallback={this.loading()}>
                  <Switch>
                    {routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => (
                            <route.component
                              {...props}
                            />
                          )} />
                      ) : (null);
                    })}
                    <Redirect from="/" to="/wall" />
                  </Switch>
                </Suspense>
              </>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooterMaxidomo username={this.props.user.username} id={this.props.user.id}/>
          </Suspense>
        </AppFooter>

        <CheckinModal></CheckinModal>

      </div>) : null
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    user: state.user,
    ui: state.ui
  }
}

export default connect(mapStateToProps)(DefaultLayoutMaxidomo);