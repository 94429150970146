import { createActionStream, createActionStreamForUsers } from './actionStreamActions';
import { MakeId, getLandlordRecipientsForActionStream } from "../../utils/helper";
import { API, graphqlOperation } from 'aws-amplify';
import { store } from '../../index';
import { ON_SHOW_ALERT_MESSAGE } from "./actions";
import { createHomeQL, createImageQL, createLandlordQL, createLandlordReturnFullQL, createPermissionsQL, updateImageQL, deleteGuestQL, deleteContractorQL } from "./graphQL/homeQL";
import { createGuestQL, createContractorQL } from "./graphQL/userQL";
import { placeNewGuestMessage, placeUnsubscribeGuestMessage, jobsiteUnsubscribeContractorMessage, jobsiteNewContractorMessage } from '../../utils/checkinMessages';

export const ON_HOME_LANDLORD_DELETE = 'ON_HOME_LANDLORD_DELETE';
export const ON_PLACE_GUEST_UNSUBSCRIBE = 'ON_PLACE_GUEST_UNSUBSCRIBE';
export const ON_HOME_IMAGE_UPDATE = 'ON_HOME_IMAGE_UPDATE';

export const ON_HOME_ADD_BY_GUEST = 'ON_HOME_ADD_BY_GUEST';
export const ON_HOME_ADD_BY_CONTRACTOR = 'ON_HOME_ADD_BY_CONTRACTOR';
export const ON_HOME_ADD_BY_LANDLORD = 'ON_HOME_ADD_BY_LANDLORD';

export const AS_PLACE_GUEST_UNSUBSCRIBE = 'AS_PLACE_GUEST_UNSUBSCRIBE';
export const AS_JOBSITE_CONTRACTOR_UNSUBSCRIBE = 'AS_JOBSITE_CONTRACTOR_UNSUBSCRIBE';
export const ON_JOBSITE_CONTRACTOR_UNSUBSCRIBE = 'ON_JOBSITE_CONTRACTOR_UNSUBSCRIBE';

export const AS_HOME_ADD_BY_GUEST = 'AS_HOME_ADD_BY_GUEST';
export const AS_HOME_ADD_BY_LANDLORD = 'AS_HOME_ADD_BY_LANDLORD';
export const AS_HOME_ADD_BY_CONTRACTOR = 'AS_HOME_ADD_BY_CONTRACTOR';

export const AS_HOME_LANDLORD_DELETE = 'AS_HOME_LANDLORD_DELETE';

export const saveAddress = (user, address, uri, onSaveAddressCallback) => {
    store.dispatch(dispatch => {
        const newHomeId = MakeId();
        const homeLandlordId = MakeId(8);

        API.graphql(graphqlOperation(createHomeQL, {
            input: {
                id: newHomeId,
                address: address,
                archived: 'n'
            }
        }))
            .then((response) => {
                if (uri !== null) {
                    API.graphql(graphqlOperation(createImageQL, {
                        input: {
                            imageUserId: user.id,
                            imageHomeId: newHomeId,
                            uri: uri
                        }
                    })).then((response2) => {
                        const newHome = {
                            ...response.data.createHome,
                            images: { items: [{ uri: response2.data.createImage.uri }] },
                            landlords: { items: [{ id: homeLandlordId, user: user }] },
                            guests: { items: [] },
                            contractors: { items: [] }
                        };
                        dispatch({ type: ON_HOME_ADD_BY_LANDLORD, value: newHome });
                        dispatch({ type: ON_SHOW_ALERT_MESSAGE, value: { text: 'Home successfully added' } })
                        onSaveAddressCallback(newHome);

                    })
                        .catch(error => {
                            console.log("Error creating the image for this home", error);
                        });
                } else {
                    const newHome = {
                        ...response.data.createHome,
                        images: { items: [] },
                        landlords: { items: [{ id: homeLandlordId, user: user }] },
                        guests: { items: [] },
                        contractors: { items: [] }
                    };
                    createActionStream(user.id,
                        newHome,
                        AS_HOME_ADD_BY_LANDLORD);
                    dispatch({ type: ON_HOME_ADD_BY_LANDLORD, value: newHome });
                    dispatch({ type: ON_SHOW_ALERT_MESSAGE, value: { text: 'Home successfully added' } })
                    onSaveAddressCallback(newHome);
                }

                API.graphql(graphqlOperation(createLandlordQL, {
                    input: {
                        id: homeLandlordId,
                        landlordHomeId: newHomeId,
                        landlordUserId: user.id
                    }
                }))
                    .catch(error => {
                        console.log("Error updating the homeLandlord", error);
                    });


            }).catch(error => {
                console.log("Error updating the address for the user", error);
            });
    });
}
export const saveCode = (user, code, onSuccess) => {
    store.dispatch(dispatch => {
        const homeLandlordId = MakeId(8);
        API.graphql(graphqlOperation(createLandlordReturnFullQL, {
            input: {
                id: homeLandlordId,
                landlordHomeId: code,
                userLandlordId: user.id
            }
        }))
            .then((response) => {
                dispatch({ type: ON_HOME_ADD_BY_GUEST, value: response.data.createHomeLandlord.home });
                placeNewGuestMessage(response.data.createHomeLandlord.home,
                    user);
                onSuccess();

            }).catch(error => {
                console.log("Error updating the code the user", error);
            });
    });
}

export const saveCodeForVisitors = (user, code, createVisitorQL, visitorHomeIdName, visitorUserIdName, createVisitorDataProp, saveCodeVisitorMessageFn, asAction, reduxAction, onSuccess) => {
    store.dispatch(dispatch => {// First we create permission

        API.graphql(graphqlOperation(createPermissionsQL, {
            input: {
                issued: (new Date()).getTime()
            }
        }))
            .then((response) => {
                const permissionId = response.data.createPermissions.id;

                API.graphql(graphqlOperation(createVisitorQL, {
                    input: {
                        [visitorHomeIdName]: code,
                        [visitorUserIdName]: user.id,
                        permissionsId: permissionId
                    }
                }))
                    .then((response2) => {

                        createActionStreamForUsers(
                            user.id,
                            getLandlordRecipientsForActionStream(response2.data[createVisitorDataProp].home, user.id),
                            response2.data[createVisitorDataProp].home,
                            asAction);

                        saveCodeVisitorMessageFn(response2.data[createVisitorDataProp].home,
                            user);

                        dispatch({ type: reduxAction, value: response2.data[createVisitorDataProp].home });
                        onSuccess();
                    }).catch(error => {
                        console.log("Error updating the createContractor code the user", error);
                    });


            }).catch(error => {
                console.log("Error updating the createContractor code the user", error);
            });
    });
}

export const saveCodeForGuests = (user, code, onSuccess) => {
    saveCodeForVisitors(user, code, createGuestQL, 'guestHomeId', 'guestUserId', 'createGuest', placeNewGuestMessage, AS_HOME_ADD_BY_GUEST, ON_HOME_ADD_BY_GUEST, onSuccess);
}

export const saveCodeForContractors = (user, code, onSuccess) => {
    saveCodeForVisitors(user, code, createContractorQL, 'contractorHomeId', 'contractorUserId', 'createContractor', jobsiteNewContractorMessage, AS_HOME_ADD_BY_CONTRACTOR, ON_HOME_ADD_BY_CONTRACTOR, onSuccess);
}

export const uploadNewImageForHome = (uri, userId, homeId, onSuccess) => {
    store.dispatch(dispatch => {
        // new image
        API.graphql(graphqlOperation(createImageQL, {
            input: {
                imageUserId: userId,
                imageHomeId: homeId,
                uri: uri
            }
        }))
            .then((response) => {
                dispatch({
                    type: ON_HOME_IMAGE_UPDATE, value: {
                        imageHomeId: homeId,
                        id: response.data.createImage.id,
                        uri: uri
                    }
                });
                onSuccess(uri);
            });
    });
}

export const updateImageForHome = (uri, imageId, userId, homeId, onSuccess) => {
    store.dispatch(dispatch => {
        API.graphql(graphqlOperation(updateImageQL, {
            input: {
                id: imageId,
                imageUserId: userId,
                imageHomeId: homeId,
                uri: uri
            }
        }))
            .then((response) => {
                dispatch({
                    type: ON_HOME_IMAGE_UPDATE, value: {
                        imageHomeId: homeId,
                        id: response.data.updateImage.id,
                        uri: uri
                    }
                });
                onSuccess(uri);
            });
    });
}

export const deleteLandlords = (userId, homeId, landlords, onDeleteHomeFn) => {
    store.dispatch(dispatch => {
    });
}

export const deleteGuests = (homeGuests) => {
    store.dispatch(dispatch => {
    });
}

export const deleteHomeVisitor = (user, deleteVisitorQL, deleteVisitorDataProp, homeVisitorId, homeUnsubscribeVisitorMessageFn, asAction, reduxAction) => {
    store.dispatch(dispatch => {
        API.graphql(graphqlOperation(deleteVisitorQL, {
            input: {
                id: homeVisitorId
            }
        })).then((response) => {
            homeUnsubscribeVisitorMessageFn(
                response.data[deleteVisitorDataProp].home,
                user);

            createActionStreamForUsers(
                user.id,
                getLandlordRecipientsForActionStream(response.data[deleteVisitorDataProp].home, user.id),
                response.data[deleteVisitorDataProp],
                asAction);

            dispatch({ type: reduxAction, value: response.data[deleteVisitorDataProp] });
        }).catch(error => {
            console.log("Error " + deleteVisitorDataProp, error);
        });
    });
}

export const deleteHomeGuest = (user, homeGuestId) => {
    deleteHomeVisitor(user, deleteGuestQL, 'deleteGuest', homeGuestId, placeUnsubscribeGuestMessage, AS_PLACE_GUEST_UNSUBSCRIBE, ON_PLACE_GUEST_UNSUBSCRIBE);
}

export const deleteHomeContractor = (user, homeContractorId) => {
    deleteHomeVisitor(user, deleteContractorQL, 'deleteContractor', homeContractorId, jobsiteUnsubscribeContractorMessage, AS_JOBSITE_CONTRACTOR_UNSUBSCRIBE, ON_JOBSITE_CONTRACTOR_UNSUBSCRIBE);
}

