/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:aea3fbbe-8b87-4cf4-99fa-5286f134a5a6",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_pcBxSV3qH",
    "aws_user_pools_web_client_id": "3bm5e59o66scfrokpe8brfn9p6",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6mexovvqmrbzxpeewhnk4duqvy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "maxixca606debed0e1410e9bbcd50a129fbebf00531-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_content_delivery_bucket": "maxixchosting01-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d2caernriq828w.cloudfront.net"
};


export default awsmobile;
