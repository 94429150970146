import * as QL_SNIPPETS from './snippets';


export const createUserQL = `mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ` + QL_SNIPPETS.ID_USER_NAME_PHONE_EMAIL + `
      }
    }`;

export const getUserQL = `query GetUser($id: ID!, $fromUserId: String) {
      getUser(id: $id) {
        ` + QL_SNIPPETS.ID_USER_NAME_PHONE_EMAIL + `
        activecheckin
        activehomeid
        ownerview
        places {
          items {
            home 
            {
              ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
              ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
              ` + QL_SNIPPETS.CHECKINS + `
            }
          }
          nextToken
        }
        jobsites {
          items {
            home 
            {
              ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
              ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
              ` + QL_SNIPPETS.CHECKINS + `
            }
          }
          nextToken
        }
        homes {
          items {
            home {
              ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
              ` + QL_SNIPPETS.CHECKINS + `
              ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
            }
          }
        }
      }
    }`;

export const deleteLandlordQL = `mutation DeleteHomeLandlord($input: DeleteHomeLandlordInput!) {
      deleteHomeLandlord(input: $input) {
          home {
              id
          }
        }
      }`;

export const createImageQL = `mutation CreateImage($input: CreateImageInput!) {
          createImage(input: $input) {
            id
          }
        }
        `;
export const updateImageQL = `mutation UpdateImage($input: UpdateImageInput!) {
          updateImage(input: $input) {
            id
          }
        }
        `;

export const deleteHomeQL = `mutation DeleteHome($input: DeleteHomeInput!) {
          deleteHome(input: $input) {
              id
            }
          }`;

export const onUpdateUserCheckin = `subscription OnUpdateUserCheckin($id: String!) {
            onUpdateUserCheckin(id: $id) {
              type
              in
              out
              rating {
                id
                scale
                feedback
                ` + QL_SNIPPETS.USER_ID_NAME + `
              }
            }
          }
          `;

export const createContractorQL = `mutation CreateContractor($input: CreateContractorInput!) {
            createContractor(input: $input) {
              id
              home {
                ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
                ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
              }
              ` + QL_SNIPPETS.USER_ID_NAME + `
            }
          }`;

export const createGuestQL = `mutation CreateGuest($input: CreateGuestInput!) {
            createGuest(input: $input) {
              id
              home {
                ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
                ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
              }
              ` + QL_SNIPPETS.USER_ID_NAME + `
            }
          }`;



