import React, { Component } from 'react';
import { Card, CardBody, Col, Input, Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../aws-config.js';
import { newCheckout } from '../../../store/actions/checkinActions';
import { getCheckinFromCheckinList, mergeHomes } from '../../../utils/helper.js';

class CheckoutPoster extends Component {


    checkoutCompleted = () => {
        this.props.postedCheckin();
    }

    newCheckout = () => {

        const checkedInHome = mergeHomes(this.props.homes, this.props.places, this.props.jobsites).filter(value => this.props.user.activehomeid === value.id);
        const homeForTheCheckin = checkedInHome !== null && typeof checkedInHome !== undefined ? checkedInHome[0] : null;

        if (homeForTheCheckin === null) {
            console.log("Error: could not find home id ", this.props.user.activehomeid, " in the homes or places for this user");
        }

        const theCheckin = {
            ...getCheckinFromCheckinList(this.props.user.activecheckin, this.props.checkins),
            home: homeForTheCheckin
        };

        newCheckout(this.props.user,
            theCheckin,
            this.checkoutCompleted);
    }

    render() {

        const checkedInAddress = mergeHomes(this.props.homes, this.props.places, this.props.jobsites).map(value => this.props.user.activehomeid === value.id ? value.address : null);

        const postingForm = (
            <FormGroup row key='formpost'>
                <Col style={{ textAlign: 'center' }}>
                    <Input
                        type="hidden"
                        name="select"
                        id="select"
                        value={this.props.homes.map((value, index) => {
                            return (this.props.user.activehomeid === value.id ?
                                value.id : null);
                        })}
                    >
                    </Input>
                    <Button
                        type="submit"
                        className="btn-pill"
                        color="danger"
                        onClick={() => this.newCheckout()}>
                        Check-out
                    </Button>
                </Col>
            </FormGroup >);

        const postingBlock = checkedInAddress === null ? null :
            (<p style={{ textAlign: 'center' }} key='message'>You are currently checked in at <strong>{checkedInAddress}.</strong></p>);

        return <Card className="text-black bg-default" >
            <CardBody>
                {[postingBlock, postingForm]}
            </CardBody>
        </Card>;
    }
}
CheckoutPoster.propTypes = {
    postedCheckin: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        checkins: state.checkins,
        homes: state.homes,
        jobsites: state.jobsites,
        places: state.places
    }
}

export default connect(mapStateToProps)(CheckoutPoster);

