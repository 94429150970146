import React, { Component } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Button
} from 'reactstrap';

import S3ImageUpload from '../../ui/S3ImageUpload';
import { connect } from 'react-redux';
import '../../../aws-config.js';
import { saveAddress } from '../../../store/actions/homeActions';
import PropTypes from 'prop-types';
import { scrollToTop } from '../../../utils/helper';

class HomeAddressEntry extends Component {

    constructor(props) {
        super(props);

        this.state = {
            address: '',
            uri: null
        };
    }

    handleAddressChange = (event) => {
        this.setState({ address: event.target.value });
    }

    onSaveAddress = (home) => {
        this.props.onModalClose();
        scrollToTop();
    }

    saveAddress = () => {
        saveAddress(this.props.user, this.state.address, this.state.uri, this.onSaveAddress);
    }

    onUploaded = uri => {
        this.setState({ uri: uri });
    }

    render() {


        let addressFooter = this.state.tempAddress !== '' ? (
            <CardFooter>
                <Button type="submit" color="primary" className="btn-pill pull-right" onClick={this.saveAddress}>Save changes</Button>
            </CardFooter>
        ) : null;

        return (
            <Card>
                <CardHeader>
                    <strong>My home address</strong>
                </CardHeader>
                <CardBody>
                    <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                        <FormGroup row>
                            <Col xs="12" md="12">
                                <Input
                                    type="text"
                                    id="text-input"
                                    name="text-input"
                                    placeholder="Address..."
                                    defaultValue={this.props.user.homeAddress}
                                    onChange={event => this.handleAddressChange(event)}
                                />
                                <FormText color="muted">Example: 300 First street, San Francisco CA 94000</FormText>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col xs="4" md="4">
                                <S3ImageUpload
                                    onUploaded={url => this.onUploaded(url)}
                                    destinationFolder={this.props.user.id + '/'}
                                ></S3ImageUpload>
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBody>
                {addressFooter}
            </Card>
        )
    }
}

HomeAddressEntry.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        homeAddress: PropTypes.string
    }),
    onModalClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateHomeId: (value) => {
            dispatch({ type: 'SET_HOME_ID', value: value })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeAddressEntry);