export const LIMIT_CHECKINS = 3;

export const ID_USER_NAME_PHONE_EMAIL = `id
username
phonenumber
email`;

export const ID_ADDRESS_IMAGES = `id
      address
      images {
          items {
              id
              uri
          }
      }`;

export const USER_ID_NAME_PHONE = `user {
    id
    username
    phonenumber
  }`;

export const USER_ID_NAME = `user {
    id
    username
  }`;

export const LANDLORDS_GUESTS_CONTRACTORS = `landlords {
    items {
      id 
      ` + USER_ID_NAME_PHONE + `
    }
  }
  guests {
    items {
      id
      ` + USER_ID_NAME_PHONE + `
    }
    nextToken
  }
  contractors {
    items {
      id
      ` + USER_ID_NAME_PHONE + `
    }
    nextToken
  }`;

export const COMMENTS = `comments (sortDirection: ASC) {
    items {
        content
        createdAt
        ` + USER_ID_NAME + `
    }
}`;

export const CHECKINS_ITEMS = `items {
    id 
    in
    out
    type
    tasktitle
    user {
        id
        username
    }
    rating {
      id
      scale
    }
    ` + COMMENTS + `
  }`;

export const CHECKINS = `checkins (
    limit: ` + LIMIT_CHECKINS + `
    sortDirection: DESC
    filter: {
      fromUserId: {
        eq: $fromUserId
      }
    }
  ) {
    ` + CHECKINS_ITEMS + `
  }`