import React, { Component } from 'react';
import {
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Button
} from 'reactstrap';

import { connect } from 'react-redux';
import { saveCodeForGuests, saveCodeForContractors } from '../../../store/actions/homeActions';
import PropTypes from 'prop-types';
import { scrollToTop } from '../../../utils/helper';

class HomeCodeEntry extends Component {

    state = { code: null, trueCode: null, userType: 'guest' };

    handleCodeChange = (event) => {
        if (event.target.value.length === 7) {
            const trueCode = event.target.value.slice(2);
            const codeCorG = event.target.value.slice(0, 1);
            const userType = codeCorG === "C" ? "contractor" : (codeCorG === "G" ? "guest" : null);
            this.setState({ code: event.target.value, userType: userType, trueCode: trueCode });
        }
    }

    onSaveCode = () => {
        this.props.onModalClose();
        scrollToTop();
    }

    saveCode = () => {

        if (this.state.userType === "contractor") {
            saveCodeForContractors(this.props.user, this.state.trueCode, this.onSaveCode);
        } else if (this.state.userType === "guest") {
            saveCodeForGuests(this.props.user, this.state.trueCode, this.onSaveCode);
        } else {
            console.log("Incorrect code");
        }
    }

    render() {

        const codeFooter = this.state.code !== null && this.state.code.length === 7 ? (
            <Button type="submit" color="primary" className="btn-pill" onClick={() => this.saveCode()}>Add home as guest</Button>
        ) : null;

        return (<><Form action="" method="post" className="form-horizontal">
            <FormGroup row>
                <Col xs="12" md="12">
                    <p>As a guest, you must enter the 7-letter code provided by the landlord.
                            This will allow you to access relevant home information and communicate with the landlord.</p>
                    <Input
                        type="text"
                        id="text-input"
                        name="text-input"
                        placeholder="Enter your code..."
                        onChange={event => this.handleCodeChange(event)}
                    />
                    <FormText color="muted">Example: C-J8DD5</FormText>
                </Col>
            </FormGroup>
        </Form>
            {codeFooter}</>)
    }
}

HomeCodeEntry.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
    }),
    onModalClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        ownerview: state.user.ownerview
    }
}

export default connect(mapStateToProps)(HomeCodeEntry);