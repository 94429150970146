import React from 'react';
import DefaultLayoutMaxidomo from './containers/DefaultLayoutMaxidomo';

const Wall = React.lazy(() => import('./views/Wall/Wall'));
const User = React.lazy(() => import('./views/User/User'));
// const Homes = React.lazy(() => import('./views/Maxidomo/Homes/Homes'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/wall', exact: true, name: 'Wall', component: Wall },
  { path: '/wall/:homeId', exact: true, name: 'Wall', component: Wall },
  { path: '/user/:userName', exact: true, name: 'Profile', component: User },
  { path: '/user', exact: true, name: 'Profile', component: User },
  // { path: '/homes', exact: true, name: 'Homes', component: Homes },
  { path: '/', exact: true, name: 'Home', component: DefaultLayoutMaxidomo },
];

export default routes;
