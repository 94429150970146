export const createActionStreamQL = `mutation CreateActionStream($input: CreateActionStreamInput!) {
    createActionStream(input: $input) {
      id
      originatorId
      recipientId
      payload
      streamType
    }
  }
  `;

export const onCreateActionStream = `subscription OnCreateActionStream($recipientId: String!) {
        onCreateActionStream(recipientId: $recipientId) {
          id
          originatorId
          recipientId
          payload
          streamType
        }
      }
      `;