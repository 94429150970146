export const unsubscribeUserFromHome = (houseType = 'homes', visitorType = 'contractors', originatorId = 'n/a', theState = null) => {
    return {
        ...theState,
        [houseType]: theState[houseType].map(theHome => {
            return {
                ...theHome, [visitorType]: {
                    items: theHome[visitorType].items.filter(theVisitor => {
                        return theVisitor.user.id !== originatorId
                    })
                }
            }
        })
    }
}

export const unsubscribeMyselfFromHome = (houseType = 'places', houseTypeIds = 'placeIds', home = null, state = null) => {
    return {
        ...state,
        user: {
            ...state.user,
            [houseTypeIds]: state.user[houseTypeIds].filter(theId => theId !== home.id)
        },
        [houseType]: [
            ...state[houseType].filter(theHome => theHome.id !== home.id)
        ]
    };
}

export const subscribeMyselfToHome = (home, houseType = 'places', houseTypeIds = 'placeIds', userIsOwnerOfHome = false, state = null) => {
    home.userIsOwnerOfHome = userIsOwnerOfHome;
    return {
        ...state,
        user: {
            ...state.user,
            [houseTypeIds]: (state.user[houseTypeIds] === undefined || state.user[houseTypeIds] === []) ? [].concat(home.id)
                : state.user[houseTypeIds].concat(home.id)
        },
        [houseType]: state[houseType].concat(home)
    };
}

export const subscribeUserToHome = (home, houseType = 'homes', visitorType = 'guests', state) => {
    return {
        ...state,
        [houseType]: state[houseType].map(theHome => { return { ...theHome, [visitorType]: home[visitorType] } })
    }
}

export const processCheckins = (checkins, newState, getHomesRsp, houseType = 'homes', houseTypeIds = 'placeIds', userIsOwnerOfHome = true) => {
    newState.user[houseTypeIds] = [];
    const homeItems = getHomesRsp[houseType].items.map(theHome => {
        return { ...theHome, home: { ...theHome.home, userIsOwnerOfHome: userIsOwnerOfHome } };
    });

    /* eslint-disable-next-line */
    for (const thisHome of homeItems) {
        if (thisHome.hasOwnProperty('home')) {
            if (thisHome.home.hasOwnProperty('checkins')) {
                /* eslint-disable-next-line */
                for (const thisHouseCheckinsList of thisHome.home.checkins.items) {
                    checkins.push({ ...thisHouseCheckinsList, targetHomeId: thisHome.home.id, userIsOwnerOfHome: true });
                }
            }
            newState.user[houseTypeIds].push(thisHome.home.id);
        }
    }
}