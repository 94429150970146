import {
    INIT_USER_SET,
    INIT_USER_DATA_GET,
    UI_LOADING,
    UI_LOADING_COMPLETE,
    CLOSE_MODAL,
    OPEN_MODAL,
    TOGGLE_MODAL,
    USER_VIEWMODE_UPDATE,
    ON_DISMISS_ALERT_MESSAGE,
    ON_SHOW_ALERT_MESSAGE
} from './actions/actions';

import {
    ON_HOME_IMAGE_UPDATE,
    ON_HOME_LANDLORD_DELETE,
    ON_HOME_ADD_BY_GUEST,
    ON_HOME_ADD_BY_LANDLORD,
    ON_PLACE_GUEST_UNSUBSCRIBE,
    AS_PLACE_GUEST_UNSUBSCRIBE,
    AS_HOME_ADD_BY_GUEST,
    AS_HOME_LANDLORD_DELETE,
    AS_HOME_ADD_BY_LANDLORD,
    ON_HOME_ADD_BY_CONTRACTOR,
    AS_HOME_ADD_BY_CONTRACTOR,
    AS_JOBSITE_CONTRACTOR_UNSUBSCRIBE,
    ON_JOBSITE_CONTRACTOR_UNSUBSCRIBE
} from './actions/homeActions';

import {
    ON_UPDATE_CHECKIN,
    ON_CHECKIN_NEW_COMMENT,
    AS_CHECKIN_NEW_COMMENT,
    AS_CHECKIN_DELETE,
    ON_CHECKIN_DELETE,
    ON_CHECKIN_CHECKOUT,
    ON_CHECKIN_NEW,
    ON_CHECKIN_RATING_UPDATE,
    ON_CHECKIN_TASKTITLE_UPDATE,
    AS_CHECKIN_TASKTITLE_UPDATE,
    ON_CHECKIN_TYPE_UPDATE,
    AS_CHECKIN_TYPE_UPDATE,
    ON_CHECKIN_MORE_RESULTS,
    AS_UPDATE_CHECKIN,
    AS_CHECKIN_NEW,

} from './actions/checkinActions';

import { ExtractHomeIndexFromHomeId } from '../utils/helper.js';
import { NO_ACTIVE_HOME, NO_ACTIVE_CHECKIN } from '../constants';
import { consolidateContacts } from './actions/userActions';
import { unsubscribeUserFromHome, unsubscribeMyselfFromHome, subscribeMyselfToHome, subscribeUserToHome, processCheckins } from './utils/reducerUtils';

export const initialState = {
    user: {
        username: 'anonymous',
        id: 'unavailable',
        homeIds: [],
        placeIds: [],
        jobsiteIds: [],
        phonenumber: [],
        email: [],
        activehomeid: NO_ACTIVE_HOME,
        activecheckin: NO_ACTIVE_CHECKIN,
        ownerview: 'guest'
    },
    checkins: [],
    homes: [],
    places: [],
    jobsites: [],
    users: [],
    ui: {
        modal: {
            open: false,
            title: 'A modal',
            type: 'UNKNOWN'
        },
        alertmessage: {
            open: false,
            text: 'Welcome to Maxidomo'
        },
        ajax: {
            loading: false,
            loadingThreads: 0,
        }
    }
}

const reducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case UI_LOADING:
            const loadingThreads = state.ui.ajax.loadingThreads + 1;
            return {
                ...state,
                ui: {
                    ...state.ui,
                    ajax: {
                        loadingThreads: loadingThreads,
                        loading: (loadingThreads > 0 ? true : false)
                    }
                }
            };
        case UI_LOADING_COMPLETE:
            const loadingThreadsComplete = state.ui.ajax.loadingThreads - 1;
            return {
                ...state,
                ui: {
                    ...state.ui,
                    ajax: {
                        loadingThreads: (loadingThreadsComplete < 0 ? 0 : loadingThreadsComplete),
                        loading: (loadingThreadsComplete > 0 ? true : false)
                    }
                }
            };
        case INIT_USER_SET:
            return {
                ...state,
                user: {
                    username: action.value.username,
                    id: action.value.attributes.sub,
                    phonenumber: [action.value.attributes.phone_number],
                    email: [action.value.attributes.email]
                }
            }
        case INIT_USER_DATA_GET:
            newState = { ...state };
            let checkins = [];

            processCheckins(checkins, newState, action.value, 'homes', 'homeIds', true);
            processCheckins(checkins, newState, action.value, 'places', 'placeIds', false);
            processCheckins(checkins, newState, action.value, 'jobsites', 'jobsiteIds', true);

            checkins.sort((checkinA, checkinB) => {
                return (parseInt(checkinB.in) - parseInt(checkinA.in));
            })

            const homes = action.value.homes.items.map(theHome => {
                return { ...theHome.home, userIsOwnerOfHome: true };
            });

            const places = action.value.places.items.map(theHome => {
                return { ...theHome.home, userIsOwnerOfHome: false };
            });

            const jobsites = action.value.jobsites.items.map(theHome => {
                return { ...theHome.home, userIsOwnerOfHome: false };
            });

            return {
                ...newState,
                user: {
                    ...state.user,
                    homeIds: newState.user.homeIds,
                    placeIds: newState.user.placeIds,
                    jobsiteIds: newState.user.jobsiteIds,
                    activecheckin: action.value.activecheckin,
                    activehomeid: action.value.activehomeid
                },
                homes: homes,
                places: places,
                jobsites: jobsites,
                checkins: checkins,
                users: consolidateContacts(action.value.homes, action.value.places, action.value.jobsites)
            };
        case USER_VIEWMODE_UPDATE:
            return { ...state, user: { ...state.user, ownerview: action.value } }
        case AS_CHECKIN_NEW:
        case ON_CHECKIN_NEW:
            newState = {
                ...state,
                user: {
                    ...state.user,
                    activecheckin: (state.user.id !== action.value.user.id || action.value.in === action.value.out) ? state.user.activecheckin : action.value.id,
                    activehomeid: (state.user.id !== action.value.user.id || action.value.in === action.value.out) ? state.user.activehomeid : action.value.targetHomeId
                }
            };

            const existingCheckinId = state.checkins.findIndex((theCheckinLocal) => {
                return theCheckinLocal.id === action.value.id;
            });

            if (!(existingCheckinId >= 0)) {
                newState.checkins.unshift({
                    ...action.value,
                    comments: {
                        items: (action.value.comments !== undefined && action.value.comments.items !== undefined) ? action.value.comments.items : []
                    }
                });
            }
            return newState;
        case ON_CHECKIN_MORE_RESULTS:
            return {
                ...state,
                checkins: state.checkins.concat(action.value)
            }
        case AS_CHECKIN_TASKTITLE_UPDATE:
        case ON_CHECKIN_TASKTITLE_UPDATE:
            return {
                ...state, checkins: state.checkins.map((theCheckinLocal) => {
                    if (theCheckinLocal.id === action.value.id) {
                        return {
                            ...theCheckinLocal,
                            tasktitle: action.value.tasktitle
                        }
                    }
                    return theCheckinLocal;
                })
            };
        case AS_CHECKIN_TYPE_UPDATE:
        case ON_CHECKIN_TYPE_UPDATE:
            return {
                ...state, checkins: state.checkins.map((theCheckinLocal) => {
                    if (theCheckinLocal.id === action.value.id) {
                        return {
                            ...theCheckinLocal,
                            type: action.value.type
                        }
                    }
                    return theCheckinLocal;
                })
            };
        case AS_UPDATE_CHECKIN:
        case ON_UPDATE_CHECKIN:

            let activecheckin = state.user.activecheckin;
            let activehomeid = state.user.activehomeid;

            const checkinToModify = state.checkins.filter(theCheckinLocal => theCheckinLocal.id === action.value.id)[0];
            if (typeof action.value.out !== undefined && action.value.out.length > 8 && checkinToModify.out !== action.value.out) {
                activecheckin = NO_ACTIVE_CHECKIN;
                activehomeid = NO_ACTIVE_HOME;
            }

            const updatedCheckinList = state.checkins.map((theCheckinLocal) => {
                if (theCheckinLocal.id === action.value.id) {
                    return {
                        ...theCheckinLocal,
                        out: action.value.out,
                        rating: action.value.rating
                    }
                }
                return theCheckinLocal;
            });

            return {
                ...state, user: {
                    ...state.user,
                    activecheckin: activecheckin,
                    activehomeid: activehomeid
                }, checkins: updatedCheckinList
            };
        case ON_CHECKIN_RATING_UPDATE:
            return {
                ...state, checkins: state.checkins.map((theCheckinLocal) => {
                    if (theCheckinLocal.rating.id === action.value.id) {
                        return {
                            ...theCheckinLocal,
                            rating: {
                                id: action.value.id,
                                scale: action.value.scale
                            }
                        }
                    }
                    return theCheckinLocal;
                })
            };
        case ON_CHECKIN_CHECKOUT:
            newState = { ...state };
            const checkinToUpdateOnCheckout = newState.checkins.findIndex(function (theCheckin) {
                return theCheckin.id === action.value.id;
            });

            if (typeof newState.checkins[checkinToUpdateOnCheckout] !== "undefined") {
                newState.checkins[checkinToUpdateOnCheckout].out = action.value.out;
            }

            return {
                ...newState, user: {
                    ...state.user,
                    activecheckin: NO_ACTIVE_CHECKIN,
                    activehomeid: NO_ACTIVE_HOME
                }
            };

        case AS_HOME_LANDLORD_DELETE:
            if (state.user.id !== action.value.originatorId) {
                return unsubscribeUserFromHome('homes', 'landlords', action.value.originatorId, state);
            }
        // eslint-disable-next-line
        case ON_HOME_LANDLORD_DELETE:
            return unsubscribeMyselfFromHome('homes', 'homeIds', action.value.home, state);

        case AS_PLACE_GUEST_UNSUBSCRIBE:
            if (state.user.id !== action.value.originatorId) {
                return unsubscribeUserFromHome('homes', 'guests', action.value.originatorId, state);
            }
        // eslint-disable-next-line
        case ON_PLACE_GUEST_UNSUBSCRIBE:
            return unsubscribeMyselfFromHome('places', 'placeIds', action.value.home, state);

        case AS_JOBSITE_CONTRACTOR_UNSUBSCRIBE:
            if (state.user.id !== action.value.originatorId) {
                return unsubscribeUserFromHome('homes', 'contractors', action.value.originatorId, state);
            }
        // eslint-disable-next-line
        case ON_JOBSITE_CONTRACTOR_UNSUBSCRIBE:
            return unsubscribeMyselfFromHome('jobsites', 'jobsiteIds', action.value.home, state);

        case AS_HOME_ADD_BY_GUEST:
            return subscribeUserToHome(action.value, 'homes', 'guests', state);
        case ON_HOME_ADD_BY_GUEST:
            return subscribeMyselfToHome(action.value, 'places', 'placeIds', false, state);

        case AS_HOME_ADD_BY_CONTRACTOR:
            return subscribeUserToHome(action.value, 'homes', 'contractors', state);
        case ON_HOME_ADD_BY_CONTRACTOR:
            return subscribeMyselfToHome(action.value, 'jobsites', 'jobsiteIds', false, state);

        case AS_HOME_ADD_BY_LANDLORD:
            return subscribeUserToHome(action.value, 'homes', 'landlords', state);
        case ON_HOME_ADD_BY_LANDLORD:
            return subscribeMyselfToHome(action.value, 'homes', 'homeIds', true, state);

        case ON_HOME_IMAGE_UPDATE:
            newState = { ...state };
            const homeSelection = ExtractHomeIndexFromHomeId(state.homes, action.value.imageHomeId);
            if (state.homes[homeSelection].images.hasOwnProperty('items') === null ||
                typeof state.homes[homeSelection].images[0] === "undefined"
            ) {
                newState.homes[homeSelection].images.items = [];
            }
            newState.homes[homeSelection].images.items[0] = { id: action.value.id, uri: action.value.uri };

            return newState;
        case AS_CHECKIN_DELETE:
        case ON_CHECKIN_DELETE:
            return {
                ...state,
                checkins: state.checkins.filter(theCheckin => theCheckin.id !== action.value.id)
            };

        case ON_SHOW_ALERT_MESSAGE:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    alertmessage: {
                        ...state.ui.alertmessage,
                        open: true,
                        text: action.value.text
                    }
                }
            };
        case ON_DISMISS_ALERT_MESSAGE:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    alertmessage: {
                        ...state.ui.alertmessage,
                        open: false,
                    }
                }
            };
        case OPEN_MODAL:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    modal: {
                        ...state.ui.modal,
                        open: true,
                        type: action.value.type,
                        title: action.value.title,
                        parameters: action.value.parameters
                    }
                }
            };
        case CLOSE_MODAL:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    modal: {
                        ...state.ui.modal,
                        open: false,
                        type: 'UNKNOWN',
                        title: 'A modal'
                    }
                }
            };

        case TOGGLE_MODAL:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    modal: {
                        ...state.ui.modal,
                        open: !state.ui.modal.open
                    }
                }
            };

        case AS_CHECKIN_NEW_COMMENT:

            return {
                ...state, checkins: state.checkins.map(function (theCheckin) {
                    if (theCheckin.id === action.value.targetCheckinId) {
                        theCheckin.comments.items.push(action.value);
                    }
                    return theCheckin;
                })
            }

        case ON_CHECKIN_NEW_COMMENT:
            
            
            return {
                ...state, checkins: state.checkins.map(function (theCheckin) {
                    if (theCheckin.id === action.value.commentCheckinId) {
                        // AWS isn't returning createdAt, so we have to "fake it"
                        var nowLocalDate = new Date();
                        action.value.createdAt = nowLocalDate.toISOString();
                        theCheckin.comments.items.push(action.value);
                    }
                    return theCheckin;
                })
            }

        default:
    }

    return state;
}

export default reducer;
