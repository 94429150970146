import { subscribeToActionStream } from "./actionStreamActions";
import { API, graphqlOperation } from 'aws-amplify';
import { store } from '../../index';
import { NO_ACTIVE_CHECKIN, NO_ACTIVE_HOME } from "../../constants";
import { UI_LOADING, UI_LOADING_COMPLETE, USER_VIEWMODE_UPDATE, INIT_USER_DATA_GET } from "./actions";
import { getUserQL } from "./graphQL/userQL";
import { createUserQL } from "./graphQL/homeQL";

export const consolidateContacts = (homes, places, jobsites) => {
    let ids = [];
    let results = [];
    if (homes.items !== undefined) {
        /* eslint-disable-next-line */
        for (const theHome of homes.items) {
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.guests.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.landlords.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.contractors.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
        }
        /* eslint-disable-next-line */
        for (const theHome of places.items) {
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.guests.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.landlords.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.contractors.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
        }
        /* eslint-disable-next-line */
        for (const theHome of jobsites.items) {
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.guests.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.landlords.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
            /* eslint-disable-next-line */
            for (const theContact of theHome.home.contractors.items) {
                if (ids.indexOf(theContact.user.id) === -1) {
                    results = [...results, theContact.user];
                    ids = [...ids, theContact.user.id];
                }
            }
        }
    }
    return results;
}

export const updateUserInterfaceWithUserData = (responseData,
    user,
    fetchUserAppData
) => {
    store.dispatch(dispatch => {
        if (responseData.getUser === null) {
            createUser(
                user,
                fetchUserAppData
            );
        } else {
            dispatch({ type: USER_VIEWMODE_UPDATE, value: responseData.getUser.ownerview === 'landlord' ? 'landlord' : 'guest' })

            subscribeToActionStream(responseData.getUser.id);

            const mergedHomes = responseData.getUser.homes.items.concat(responseData.getUser.places.items).concat(responseData.getUser.jobsites.items);
            if (mergedHomes.length > 0) {
                dispatch({
                    type: INIT_USER_DATA_GET, value:
                    {
                        homes: responseData.getUser.homes,
                        places: responseData.getUser.places,
                        jobsites: responseData.getUser.jobsites,
                        activecheckin: (null === responseData.getUser.activecheckin) ? NO_ACTIVE_CHECKIN : responseData.getUser.activecheckin,
                        activehomeid: (null === responseData.getUser.activehomeid) ? NO_ACTIVE_HOME : responseData.getUser.activehomeid
                    }
                });
            }
        }
    });
}

export const fetchUserAppData = (user) => {
    store.dispatch(dispatch => {
        dispatch({ type: UI_LOADING })
        API.graphql(graphqlOperation(getUserQL, { id: user.id, fromUserId: user.id }))
            .then((response) => {
                dispatch({ type: UI_LOADING_COMPLETE })
                updateUserInterfaceWithUserData(
                    response.data,
                    user,
                    fetchUserAppData
                );
            }).catch(error => {
                console.log("initializeUiForUser error", error);
            });
    });
}

export const createUser = (user,
    onCreateSuccess) => {
    const userDetails = {
        id: user.id,
        username: user.username,
        phonenumber: user.phonenumber[0],
        email: user.email,
        ownerview: 'guest'
    };

    API.graphql(graphqlOperation(createUserQL, { input: userDetails }))
        .then((response) => {
            onCreateSuccess(
                user);
        }).catch(error => {
            console.log("Error usercreateUserGraphQLname", error);
        });
}

export const updateOwnerView = (userId, selectedView) => {
    const theInput = {
        id: userId,
        ownerview: selectedView
    };

    API.graphql(graphqlOperation(`mutation UpdateUser($input: UpdateUserInput!) {
          updateUser(input: $input) {
            id
          }
        }
        `, {
            input: theInput
        })).catch(error => {
            console.log("updateOwnerView error", error);
        });

}