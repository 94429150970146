import * as QL_SNIPPETS from './snippets';

export const createUserQL = `mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
        ` + QL_SNIPPETS.ID_USER_NAME_PHONE_EMAIL + `
      }
    }`;

export const deleteLandlordQL = `mutation DeleteHomeLandlord($input: DeleteHomeLandlordInput!) {
      deleteHomeLandlord(input: $input) {
          home {
              id
          }
        }
      }`;

export const createImageQL = `mutation CreateImage($input: CreateImageInput!) {
          createImage(input: $input) {
            id
          }
        }
        `;
export const updateImageQL = `mutation UpdateImage($input: UpdateImageInput!) {
          updateImage(input: $input) {
            id
          }
        }
        `;

export const deleteHomeQL = `mutation DeleteHome($input: DeleteHomeInput!) {
          deleteHome(input: $input) {
              id
            }
          }`;

export const onUpdateUserCheckin = `subscription OnUpdateUserCheckin($id: String!) {
            onUpdateUserCheckin(id: $id) {
              type
              in
              out
              rating {
                id
                scale
                feedback
                ` + QL_SNIPPETS.USER_ID_NAME + `
              }
            }
          }
          `;

export const createCheckinQL = `mutation CreateCheckin($input: CreateCheckinInput!) {
            createCheckin(input: $input) {
              id
              in
              targetHomeId
              out
              type
              ` + QL_SNIPPETS.USER_ID_NAME + `
            }
          }
          `;

export const updateActiveCheckinQL = `mutation UpdateUser($input: UpdateUserInput!) {
            updateUser(input: $input) {
              id
            }
          }
          `;

export const updateCheckinQL = `mutation UpdateCheckin($input: UpdateCheckinInput!) {
            updateCheckin(input: $input) {
              id
              tasktitle
              in
              out
            }
          }
          `;

export const getMoreCheckinsForHome = `query GetHome($id: ID!, $limit: Int, $nextToken: String) {
            getHome(id: $id) {
                    id
                    checkins (sortDirection: DESC, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        items {
                            id
                            home {
                                ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
                            }
                            in
                            out
                            ` + QL_SNIPPETS.COMMENTS + `
                            ` + QL_SNIPPETS.USER_ID_NAME + `
                        }
                    }
                }
            }
          `;

export const getMoreCheckinsForPlace = `query GetHome($id: ID!, $fromUserId: String, $limit: Int, $nextToken: String) {
            getHome(id: $id) {
                    id
                    checkins (sortDirection: DESC, limit: $limit, nextToken: $nextToken, filter: {
                        fromUserId: {
                          eq: $fromUserId
                        }
                      }) {
                        nextToken
                        items {
                            id
                            targetHomeId
                            home {
                                ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
                            }
                            in
                            out
                            ` + QL_SNIPPETS.COMMENTS + `
                            ` + QL_SNIPPETS.USER_ID_NAME + `
                        }
                    }
                }
            }
          `;

export const getCheckinQL = `query GetCheckin($id: ID!) {
            getCheckin(id: $id) {
              id
              home {
                ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
              }
              ` + QL_SNIPPETS.USER_ID_NAME + `
              in
              out
              ` + QL_SNIPPETS.COMMENTS + `
              rating {
                id
                scale
                feedback
              }
            }
          }
          `;

