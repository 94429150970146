import React, { Component } from 'react';
import { Card, CardBody, Col, Input, Button, FormGroup } from 'reactstrap';
import '../../../aws-config.js';
import { CHECKIN_TYPE, CHECKIN_BUTTON_WORD } from '../../../constants.js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PleaseWait from '../../ui/PleaseWait.js';
import { createCheckin } from '../../../store/actions/checkinActions';

class CheckinPoster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedHome: this.props.homes.length > 0 ? this.props.homes[0] : null,
            processing: false,
            commentContent: ''
        };
    }

    onCreateCheckin = () => {
        this.setState({ processing: false });
        this.props.postedCheckin();
    }

    handlePostChange(e) {
        this.setState({ commentContent: e.target.value });
      }

    submitCheckin = () => {
        this.setState({ processing: true });
        createCheckin(CHECKIN_TYPE.GUEST_CHECKIN,
            this.state.selectedHome,
            this.props.user,
            this.state.commentContent,
            this.onCreateCheckin);
    }

    handleSelectionChange = (event) => {
        this.setState({
            selectedHome: this.props.homes[event.target.value]
        });
    }

    render() {

        return this.state.processing ? <PleaseWait /> : (<Card className="text-black bg-default">
            <CardBody style={{ backgroundColor: '#ddd' }}>
                <FormGroup row>
                    <Col xs="12" sm="12" md="12">
                    <Input
                            type="select"
                            onChange={(e) => this.handleSelectionChange(e)}                        >
                            {this.props.homes.map((value, index) => {
                                return <option value={index} key={'option' + index}>{value.address} #{value.id}</option>
                            })}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" sm="12" md="12">
                        Message
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="6" sm="8" md="9">
                    <Input
                            type="txt"
                            onChange={e => this.handlePostChange(e)}>
                        </Input>
                    </Col>
                    <Col xs="6" sm="4" md="3" style={{ textAlign: 'right' }}>
                        <Button type="submit" className="btn-pill" color="success" onClick={this.submitCheckin}>{CHECKIN_BUTTON_WORD}</Button>
                    </Col>
                </FormGroup>
            </CardBody>
        </Card>);
    }
}

CheckinPoster.propTypes = {
    postedCheckin: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        homes: state.places.concat(state.homes).concat(state.jobsites)
    }
}

export default connect(mapStateToProps)(CheckinPoster);

