import * as QL_SNIPPETS from './snippets';

export const createHomeQL = `mutation CreateHome($input: CreateHomeInput!) {
  createHome(input: $input) {
    id
    address
  }
}
`;

export const createLandlordQL = `mutation CreateLandlord($input: CreateLandlordInput!) {
  createLandlord(input: $input) {
    id
  }
}
`;

export const createLandlordReturnFullQL = `mutation CreateLandlord($input: CreateLandlordInput!) {
  createLandlord(input: $input) {
    id
    home {
      ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
      ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
    }
    landlord {
      ` + QL_SNIPPETS.ID_USER_NAME_PHONE_EMAIL + `
      name
    }
  }
}
`;

export const createUserQL = `mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ` + QL_SNIPPETS.ID_USER_NAME_PHONE_EMAIL + `
      }
    }`;

export const createPermissionsQL = `mutation CreatePermissions($input: CreatePermissionsInput!) {
      createPermissions(input: $input) {
        id
      }
    }
    `;

export const deleteLandlordQL = `mutation DeleteLandlord($input: DeleteLandlordInput!) {
      deleteLandlord(input: $input) {
        home {
          ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
          ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
        }
      }
    }`;

export const deleteGuestQL = `mutation DeleteGuest($input: DeleteGuestInput!) {
        deleteGuest(input: $input) {
          home {
            ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
            ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
          }
          }
        }`;

export const deleteContractorQL = `mutation DeleteContractor($input: DeleteContractorInput!) {
  deleteContractor(input: $input) {
    home {
      ` + QL_SNIPPETS.ID_ADDRESS_IMAGES + `
      ` + QL_SNIPPETS.LANDLORDS_GUESTS_CONTRACTORS + `
    }
    }
  }`;

export const createImageQL = `mutation CreateImage($input: CreateImageInput!) {
          createImage(input: $input) {
            id
            uri
          }
        }
        `;
export const updateImageQL = `mutation UpdateImage($input: UpdateImageInput!) {
          updateImage(input: $input) {
            id
          }
        }
        `;

export const deleteHomeQL = `mutation DeleteHome($input: DeleteHomeInput!) {
          deleteHome(input: $input) {
              id
            }
          }`;

export const onUpdateUserCheckin = `subscription OnUpdateUserCheckin($id: String!) {
            onUpdateUserCheckin(id: $id) {
              type
              in
              out
              rating {
                id
                scale
                feedback
                ` + QL_SNIPPETS.USER_ID_NAME + `
              }
            }
          }
          `;

