import { createActionStreamForUsers } from "./actionStreamActions";
import { getCheckinRecipientsForActionStream, getLandlordRecipientsForActionStream } from "../../utils/helper";
import { API, graphqlOperation } from 'aws-amplify';
import { store } from '../../index';
import { NO_ACTIVE_CHECKIN, NO_ACTIVE_HOME, LEFT_HOME_DEFAULT, checkinTypeImmediatelySetsWhenOut } from "../../constants";
import { updateCheckinQL, updateActiveCheckinQL, getCheckinQL, getMoreCheckinsForHome, getMoreCheckinsForPlace, createCheckinQL } from './graphQL/checkinQL';

export const ON_CHECKIN_NEW_COMMENT = 'ON_CHECKIN_NEW_COMMENT';
export const ON_CHECKIN_RATING_UPDATE = 'ON_CHECKIN_RATING_UPDATE';
export const ON_CHECKIN_TASKTITLE_UPDATE = 'ON_CHECKIN_TASKTITLE_UPDATE';
export const AS_CHECKIN_TASKTITLE_UPDATE = 'AS_CHECKIN_TASKTITLE_UPDATE';
export const ON_CHECKIN_TYPE_UPDATE = 'ON_CHECKIN_TYPE_UPDATE';
export const AS_CHECKIN_TYPE_UPDATE = 'AS_CHECKIN_TYPE_UPDATE';
export const ON_NEW_CHECKIN_RECEIVED = 'ON_NEW_CHECKIN_RECEIVED';

export const ON_CHECKIN_NEW = 'ON_CHECKIN_NEW';

export const ON_CHECKIN_DELETE = 'ON_CHECKIN_DELETE';
export const ON_UPDATE_CHECKIN_RATING = 'ON_UPDATE_CHECKIN_RATING';
export const ON_CHECKIN_CHECKOUT = 'ON_CHECKIN_CHECKOUT';
export const ON_UPDATE_CHECKIN = 'ON_UPDATE_CHECKIN';

export const ON_CHECKIN_MORE_RESULTS = 'ON_CHECKIN_MORE_RESULTS';
export const AS_CHECKIN_NEW_COMMENT = 'AS_CHECKIN_NEW_COMMENT';
export const AS_CHECKIN_NEW = 'AS_CHECKIN_NEW';
export const AS_UPDATE_CHECKIN = 'AS_UPDATE_CHECKIN';
export const AS_CHECKIN_DELETE = 'AS_CHECKIN_DELETE';

export const newCheckinActionCreator = (value) => {
    return {
        type: 'ON_CHECKIN_NEW',
        value: value
    };
}

export const newComment = (comment, checkin, user, onError) => {
    store.dispatch(dispatch => {

        const theInput = {
            content: comment,
            targetCheckinId: checkin.id,
            commentCheckinId: checkin.id,
            commentUserId: user.id
        };

        const theNewCommentInput = {
            ...theInput, user: {
                id: user.id,
                username: user.username
            },
            comments: {
                items: []
            }
        }

        return API.graphql(graphqlOperation(`mutation CreateComment($input: CreateCommentInput!) {
            createComment(input: $input) {
            id
            createdAt
            content
            targetCheckinId
            user {
                id
                username
            }
            }
            }`, {
            input: theInput
        })).then(response => {
            createActionStreamForUsers(user.id,
                getCheckinRecipientsForActionStream(checkin, user.id),
                response.data.createComment,
                AS_CHECKIN_NEW_COMMENT);
            dispatch({
                type: 'ON_CHECKIN_NEW_COMMENT',
                value: theNewCommentInput
            });
        }).catch(error => {
            console.log("createComment error", error);
            onError();
        });
    });
}

export const saveCheckinRating = (checkin, userId, rating) => {
    store.dispatch(dispatch => {
        if (checkin.rating === null) {
            API.graphql(graphqlOperation(`mutation CreateRating($input: CreateRatingInput!) {
            createRating(input: $input) {
              id
              scale
            }
          }
          `, {
                input: {
                    scale: checkin.rating,
                    ratingCheckinId: checkin.id,
                    ratingUserId: userId
                }
            }))
                .then((response) => {
                    store.dispatch({ type: ON_CHECKIN_RATING_UPDATE, value: { ...response.data.createRating } });
                    createActionStreamForUsers(userId, getCheckinRecipientsForActionStream(checkin, userId), response.data.updateCheckin, AS_UPDATE_CHECKIN);
                }).catch(error => {
                    console.log("Error creating the rating", error);
                });
        } else {
            API.graphql(graphqlOperation(`mutation UpdateRating($input: UpdateRatingInput!) {
            updateRating(input: $input) {
              id
              scale
            }
          }
          `, {
                input: {
                    scale: rating,
                    id: checkin.id,
                    ratingUserId: userId
                }
            }))
                .then((response) => {
                    store.dispatch({ type: ON_CHECKIN_RATING_UPDATE, value: { ...response.data.updateRating } });
                    console.log("TODO: Update rating was not implemented using Active Stream");
                }).catch(error => {
                    console.log("Error updating the rating", error);
                });
        }
    });
}

export const updateCheckinTaskTitle = (checkin, titleText) => {
    store.dispatch(dispatch => {
        API.graphql(graphqlOperation(updateCheckinQL, {
            input: {
                id: checkin.id,
                tasktitle: titleText
            }
        })).then((response) => {
                store.dispatch({ type: ON_CHECKIN_TASKTITLE_UPDATE, value: { ...response.data.updateCheckin } });
            }).catch(error => {
                console.log("Error updating the checkinTaskTitle", error);
            });
    });
}

export const updateCheckinType = (checkin, newType) => {
    store.dispatch(dispatch => {
        API.graphql(graphqlOperation(updateCheckinQL, {
            input: {
                id: checkin.id,
                type: newType
            }
        })).then((response) => {
                store.dispatch({ type: ON_CHECKIN_TYPE_UPDATE, value: { ...response.data.updateCheckin } });
            }).catch(error => {
                console.log("Error updating the updateCheckinType", error);
            });
    });
}

export const newCheckout = (user, checkin, onSuccess) => {
    store.dispatch(dispatch => {
        const checkoutActionStreamRecipients = getCheckinRecipientsForActionStream(checkin, user.id)

        const when = '' + (new Date()).getTime();

        const originalActivecheckin = user.activecheckin;

        API.graphql(graphqlOperation(updateActiveCheckinQL, {
            input: {
                id: user.id,
                activecheckin: NO_ACTIVE_CHECKIN,
                activehomeid: NO_ACTIVE_HOME,
            }
        })).then(response2 => {

            store.dispatch({ type: ON_CHECKIN_CHECKOUT, value: { id: originalActivecheckin, out: when } })

            API.graphql(graphqlOperation(updateCheckinQL, {
                input: {
                    id: originalActivecheckin,
                    out: when
                }
            })).then((response) => {
                onSuccess();

                if (checkin !== null) {
                    createActionStreamForUsers(user.id, checkoutActionStreamRecipients, response.data.updateCheckin, AS_UPDATE_CHECKIN);
                } else {
                    console.log("newcheckout received null for checkin");
                }


            }).catch(error => {
                onSuccess();
                console.log("Note: original checkin might have been deleted", error);
            });

        }).catch(error => {
            console.log("Can't reset the user active location", error);
        });
    });
}

export const createCheckin = (checkinType, home, user, comment, onCreateCheckinFn) => {
    store.dispatch(dispatch => {
        const whenIn = '' + (new Date()).getTime();
        const whenOut = checkinTypeImmediatelySetsWhenOut(checkinType) ? whenIn : LEFT_HOME_DEFAULT;
        const input = {
            checkinHomeId: home.id,
            targetHomeId: home.id,
            checkinUserId: user.id,
            fromUserId: user.id,
            tasktitle: comment,
            in: whenIn,
            out: whenOut,
            archived: 'n',
            type: checkinType
        };

        API.graphql(graphqlOperation(createCheckinQL, {
            input: input
        })).then(response => {
            store.dispatch(newCheckinActionCreator({
                ...input,
                id: response.data.createCheckin.id,
                home: home,
                user: user,
                comments: {
                    items: []
                }
            }));

            if (whenIn !== whenOut) {
                API.graphql(graphqlOperation(updateActiveCheckinQL, {
                    input: {
                        id: user.id,
                        activecheckin: response.data.createCheckin.id,
                        activehomeid: home.id
                    }
                })).then(() => {
                    createActionStreamForUsers(user.id,
                        getLandlordRecipientsForActionStream(home, user.id),
                        response.data.createCheckin,
                        AS_CHECKIN_NEW);
                    if (typeof onCreateCheckinFn === "function") {
                        onCreateCheckinFn();
                    }
                }).catch(error => {
                    console.log("post checkin error", error);
                });
            } else {
                createActionStreamForUsers(user.id,
                    getLandlordRecipientsForActionStream(home, user.id),
                    response.data.createCheckin,
                    AS_CHECKIN_NEW);
                if (typeof onCreateCheckinFn === "function") {
                    onCreateCheckinFn();
                }
            }

        }).catch(error => {
            console.log("postUpdate error", error);
        });
    });
}

export const deleteCheckinExecute = (checkin, user) => {
    store.dispatch(dispatch => {
        API.graphql(graphqlOperation(`mutation DeleteCheckin($input: DeleteCheckinInput!) {
      deleteCheckin(input: $input) {
        id
        targetHomeId
      }
    }
    `, {
            input: {
                id: checkin.id
            }
        })).then(response => {
            dispatch({ type: ON_CHECKIN_DELETE, value: { id: checkin.id } });
            createActionStreamForUsers(user.id, getLandlordRecipientsForActionStream(checkin.home, user.id), response.data.deleteCheckin, AS_CHECKIN_DELETE);
        }).catch(error => {
            console.log("DeleteCheckin error", error);
        });
    });
}

export const getCheckin = (checkinId, userId) => {
    store.dispatch(dispatch => {
        API.graphql(graphqlOperation(getCheckinQL,
            {
                id: checkinId
            }))
            .then((response) => {
                dispatch({ type: AS_CHECKIN_NEW, value: response.data.getCheckin })
            }).catch(error => {
                console.log("getCheckin error", error);
            });
    });
}

export const getMoreCheckins = (homeId, limit, nextToken, onResponseFn) => {
    console.log("getMoreCheckinsForHome");
    store.dispatch(dispatch => {
        API.graphql(graphqlOperation(getMoreCheckinsForHome,
            {
                id: homeId,
                limit: limit,
                nextToken: nextToken
            }))
            .then((response) => {
                response.data.getHome.checkins.items.map(checkin => {
                    dispatch({ type: ON_CHECKIN_MORE_RESULTS, value: checkin });
                    return false;
                });
                onResponseFn();
            }).catch(error => {
                console.log("initializeUiForUser error", error);
            });
    });
}


export const getMoreCheckinsPlace = (userId, homeId, limit, nextToken, onSuccess) => {
    store.dispatch(dispatch => {
        API.graphql(graphqlOperation(getMoreCheckinsForPlace,
            {
                id: homeId,
                fromUserId: userId,
                limit: limit,
                nextToken: nextToken
            }))
            .then((response) => {
                response.data.getHome.checkins.items.map(checkin => {
                    dispatch({ type: ON_CHECKIN_MORE_RESULTS, value: checkin });
                    return false;
                });
                onSuccess(response.data.getHome);
            }).catch(error => {
                console.log("getMoreCheckinsPlace error", error);
            });
    });
}