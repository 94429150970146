import { API, graphqlOperation } from 'aws-amplify';
import { store } from '../../index';
import { createActionStreamQL, onCreateActionStream } from './graphQL/actionStreamQL';
import { AS_CHECKIN_NEW_COMMENT, getCheckin } from './checkinActions';

export const putActionStreamId = (id) => {
    sessionStorage.setItem("AS_" + id, "1");
}

export const actionStreamIdExists = (id) => {
    return ("1" === sessionStorage.getItem("AS_" + id));
}

export const deleteActionStreamId = (id) => {
    sessionStorage.removeItem("AS_" + id);
}


export const createActionStreamForUsers = (originatorId, recipients, payload, streamType, includeOriginator = true) => {

    if (includeOriginator && recipients.indexOf(originatorId) === -1)
        recipients.push(originatorId);

    /* eslint-disable-next-line */
    for (const recipientId of recipients) {
        API.graphql(graphqlOperation(createActionStreamQL, { input: { originatorId: originatorId, recipientId: recipientId, payload: JSON.stringify(payload), streamType: streamType } }))
            .then(response => {
                putActionStreamId(response.data.createActionStream.id);
            }).catch(error => {
                console.log("Error createActionStream", error);
            });
    }
}

export const createActionStream = (recipientId, payload, streamType) => {
    API.graphql(graphqlOperation(createActionStreamQL, { input: { recipientId: recipientId, payload: JSON.stringify(payload), streamType: streamType } }))
        .then(response => {
            putActionStreamId(response.data.createActionStream.id);
        }).catch(error => {
            console.log("Error createActionStream", error);
        });
}

export const newCommentReceived = (dispatch, getState, deltaSyncPayload, userId) => {
    const targetCheckin = getState().checkins.filter(checkin => checkin.id === deltaSyncPayload.targetCheckinId);
    if (targetCheckin.length > 0) {
        dispatch({ type: AS_CHECKIN_NEW_COMMENT, value: deltaSyncPayload });
    } else {
        getCheckin(deltaSyncPayload.targetCheckinId, userId);
    }
}

export const subscribeToActionStream = (userId) => {
    store.dispatch((dispatch, getState) => {
        API.graphql(
            graphqlOperation(onCreateActionStream, { recipientId: userId })
        ).subscribe({
            next: (eventData) => {
                const actionStreamData = eventData.value.data.onCreateActionStream;
                if (actionStreamIdExists(actionStreamData.id) === false) {
                    const deltaSyncPayload = { ...JSON.parse(actionStreamData.payload), originatorId: actionStreamData.originatorId };
                    if (actionStreamData.streamType === AS_CHECKIN_NEW_COMMENT) {
                        newCommentReceived(dispatch, getState, deltaSyncPayload, userId);
                    } else {
                        store.dispatch({ type: actionStreamData.streamType, value: deltaSyncPayload });
                    }
                } else {
                    deleteActionStreamId(actionStreamData.id);
                }
            }
        });
    });
}